const countFormatter = {
  methods: {
    formatCount(count) {
      if (count < 1000) {
        return count.toString();
      } else if (count < 1000000) {
        const formattedCount = Math.floor(count / 1000);
        return `+${formattedCount}K`;
      } else {
        const formattedCount = Math.floor(count / 1000000);
        return `+${formattedCount}M`;
      }
    }
  }
};
export default countFormatter;
